import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLandingScrollDisabled: false,
    isPaymentScrollDisabled: false,
    urlParams: '',
    pageId: '',
    pageName: '',
    email: '',
    utm: {},
    plan: {},
    remoteKeys: {},
    couponData: {},
};

const configSlice = createSlice({
    name: 'analytics',
    initialState,
    reducers: {
        setLandingScrollDisabled(state, action) {
            state.isLandingScrollDisabled = action.payload;
        },
        setPaywallScrollDisabled(state, action) {
            state.isPaymentScrollDisabled = action.payload;
        },
        setUrlParams(state, action) {
            state.urlParams = action.payload;
        },
        setPlan(state, action) {
            state.plan = action.payload;
        },
        setRemoteConfigValue(state, action) {
            state.remoteKeys = {
                ...state.remoteKeys,
                [action.payload.key]: action.payload.value,
            };
        },
        setPageName(state, action) {
            state.pageName = action.payload;
        },
        setPageId(state, action) {
            state.pageId = action.payload;
        },
        setEmail(state, action) {
            state.email = action.payload;
        },
        setUTMParams(state, action) {
            state.utm = action.payload;
        },
        setCouponData(state, action) {
            state.couponData = action.payload;
        },
    },
});

export default configSlice.reducer;
export const {
    setLandingScrollDisabled,
    setPaywallScrollDisabled,
    setUrlParams,
    setPlan,
    setPageName,
    setPageId,
    setEmail,
    setUTMParams,
    setRemoteConfigValue,
    setCouponData,
} = configSlice.actions;
